<template>
	<!-- 项目-第三方账号 -->
	<div class="container">
		<GModule>
			<GTitle name="第三方"></GTitle>
			<div class="third-box">
				<div class="flex">
					<p>选择所需第三方</p>
					<a-button class="clo3" @click="addThird()">添加</a-button>
				</div>
				<a-checkbox-group class="check-box">
				    <a-row>
				      <a-col>
				        <a-checkbox value="A">A</a-checkbox>
				      </a-col>
				      <a-col>
				        <a-checkbox value="B">Bbbbbbbbbbbbbbbbbbbbbbbbbbb</a-checkbox>
				      </a-col>
				      <a-col>
				        <a-checkbox value="C">C第三方啦啦啦啦啦啦啦啦啦</a-checkbox>
				      </a-col>
				      <a-col>
				        <a-checkbox value="D">我不知道为什么事情会这样，但是我知道</a-checkbox>
				      </a-col>
				      <a-col>
				        <a-checkbox value="E">当身边的人开始跑起来，注定只有自己才靠得住</a-checkbox>
				      </a-col>
					  <a-col>
					    <a-checkbox value="A">A</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="B">B</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="C">C</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="D">D</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="E">E</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="A">A</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="B">B</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="C">C</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="D">D</a-checkbox>
					  </a-col>
					  <a-col>
					    <a-checkbox value="E">E</a-checkbox>
					  </a-col>
				    </a-row>
				  </a-checkbox-group>
			</div>
			<div style="margin: 10px 0;">
				<a-button class="clo2">导出</a-button>
				<a-button class="clo1" style="margin-left: 20px;">提交</a-button>
			</div>
			<a-table
				class="ttable" 
				sticky 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500,y:250 }" 
				bordered
				:pagination="false">
			</a-table>
			<div class="documentFt">
			  <a-pagination size="small" :total="50" />
			</div>
		</GModule>	
	</div>
</template>

<script>
	export default{
		data(){
			return{
				columnsHe: [{
				    title: '序号',
				    width: 80,
				    dataIndex: 'index',
				    key: 'index',
				    fixed: 'left',
				  }, {
				    title: '第三方名称',
				    width: 100,
				    dataIndex: 'name',
				    key: 'describe',
				    fixed: 'left',
				  }, {
				    title: '注册地址',
				    width: 120,
				    dataIndex: 'image',
				    key: 'image'
				  }, {
				    title: '注册完成账号',
				    width: 100,
				    dataIndex: 'age',
				    key: 'age'
				  }, {
				    title: '登录密码',
				    width: 100,
				    dataIndex: 'age',
				    key: 'age'
				  }, {
				    title: '开发者ID',
				    width: 100,
				    dataIndex: 'age',
				    key: 'age'
				  }, , {
				    title: '开发者密码',
				    width: 100,
				    dataIndex: 'age',
				    key: 'age'
				  }, {
				    title: '原始ID',
				    width: 100,
				    dataIndex: 'age',
				    key: 'age'
				  }, {
				    title: '商户秘钥',
				    width: 100,
				    dataIndex: 'age',
				    key: 'age'
				  }],
				data: [{
				  key: '1',
				  name: 'John Brown',
				  age: 32,
				  address: 'New York No. 1 Lake Park',
				}, {
				  key: '2',
				  name: 'Jim Green大家聚聚军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军',
				  age: 42,
				  address: 'London No. 1 Lake Park',
				}, {
				  key: '3',
				  name: 'Joe Black',
				  age: 32,
				  images:[],
				  address: 'Sidney No. 1 Lake Park',
				}, {
				  key: '4',
				  name: 'Jim Red',
				  age: 32,
				  address: 'London No. 2 Lake Park',
				},{
				  key: '5',
				  name: 'John Brown',
				  age: 32,
				  address: 'New York No. 1 Lake Park',
				}, {
				  key: '6',
				  name: 'Jim Green',
				  age: 42,
				  address: 'London No. 1 Lake Park',
				}, {
				  key: '7',
				  name: 'Joe Black',
				  age: 32,
				  address: 'Sidney No. 1 Lake Park',
				}, {
				  key: '8',
				  name: 'Jim Red',
				  age: 32,
				  address: 'London No. 2 Lake Park',
				}],
			}
		},
		methods:{
			addThird(){
				this.$store.commit('setIsThirdAdd',1)
			}
		}
	}
</script>

<style scoped lang="scss">
.container{
	margin: 20px;
	.third-box{
		padding: 10px;
		background-color: #f5f5f5;
		.flex{
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 2px solid #ffffff;
			padding: 5px 0;
			margin-bottom: 10px;
			p{
				margin: 0;
				padding: 0;
			}
		}
		.check-box{
			width: 100%;
		}
	}
}
</style>